import { Addon, TripAddon, TripId } from '@tripr/common';
import { makeApiCall } from './ImagesApi';

export class AddonsApi {
  public static getAddons = async () => {
    try {
      const response = await makeApiCall<{ statusCode: number; addons: Addon[] }>('GET', `Trips/addons`);
      console.log(response);
      return response.addons;
    } catch (e) {
      console.log(e);
    }
  };

  public static getTripAddons = async (tripVersionId: TripId) => {
    try {
      const response = await makeApiCall<{ statusCode: number; addons: TripAddon[] }>('GET', `Trips/${tripVersionId}/addons`);
      console.log(response);
      return response.addons;
    } catch (e) {
      console.log(e);
    }
  };

  public static saveTripAddons = async (tripId: TripId, data: Partial<TripAddon>[]) => {
    try {
      const response = await makeApiCall<{ statusCode: number; tripAddons: Partial<TripAddon>[] }>(
        'POST',
        `Trips/${tripId}/addons/update`,
        JSON.stringify(data),
      );
      console.log(response);
      if (response.tripAddons) return response.tripAddons;
      return [];
    } catch (e) {
      console.log(e);
    }
  };
}
