import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TripAddon } from '@tripr/common';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  row: {
    marginBottom: theme.spacing(2),
  },
}));

export const AccommodationUpgrades = ({
  initialUpgrades,
  onChange,
}: {
  initialUpgrades: Partial<TripAddon>[] | undefined;
  onChange: (upgrades: Partial<TripAddon>[]) => void;
}) => {
  if (!initialUpgrades) return null;
  const classes = useStyles();
  const [upgrades, setUpgrades] = useState(initialUpgrades);

  useEffect(() => {
    console.log('onchange called');
    onChange(upgrades);
  }, [upgrades, onChange]);

  const handleInputChange = (id: any, field: string, value: string | boolean) => {
    setUpgrades(upgrades.map(upgrade => (upgrade.title == id ? { ...upgrade, [field]: value } : upgrade)));
  };

  return (
    <Paper className={classes.root}>
      {upgrades.map((upgrade: Partial<TripAddon>) => (
        <Grid container spacing={2} className={classes.row} key={upgrade.id}>
          <Grid item>
            <Checkbox checked={upgrade?.isVisible || false} onChange={e => handleInputChange(upgrade.title, 'isVisible', e.target.checked)} />
          </Grid>
          <Grid style={{ marginTop: '0.5rem' }} item xs={2}>
            <Typography>{upgrade.title}</Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              value={upgrade.price}
              onChange={e => handleInputChange(upgrade.title, 'price', e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          {upgrade.maxQuantity != undefined ? (
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                placeholder="maxQuantity"
                value={upgrade.maxQuantity}
                onChange={e => handleInputChange(upgrade.title, 'maxQuantity', e.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item xs>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              // multiline
              // rows={2}
              value={upgrade.description}
              onChange={e => handleInputChange(upgrade.title, 'description', e.target.value)}
              placeholder="Custom Text e.g. we will accommodate you in town xyz"
            />
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
};
