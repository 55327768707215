import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  Accommodation,
  AccommodationType,
  Currency,
  DifficultyLevel,
  ExperienceLevel,
  Faq,
  HostId,
  DepartureType,
  IsoCountryCode,
  isoCountryCodeList,
  isoCountryMap,
  Itinerary,
  MaybeMoney,
  Money,
  PetAllowance,
  PricePerPerson,
  RelatedArticleTags,
  RecommendedTrip,
  TourType,
  TripId,
  TripType,
  UploadedImage,
  WhatIsExcludedDefault,
  WhatIsIncluded,
  WhatIsIncludedDefault,
  hasAdminAccess,
  TripStatus,
  CustomTripMessage,
} from '@tripr/common';
import arrayMutators from 'final-form-arrays';
import React, { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import * as yup from 'yup';
import { NumberSchema, Schema, StringSchema } from 'yup';
import { ImageArray, OptionalImage } from '../../../api/CommonSchemas';
import { ImagesApi } from '../../../api/ImagesApi';
import { useAuthContext } from '../../../utils/AuthContext';
import { MySelect } from '../../common/forms/MySelect';
import { MyPricePerPersonField } from '../../common/forms/PricePerPersonField';
import { BooleanField, MyTextField } from '../../common/forms/TextField';
import { yupValidate } from '../../common/forms/YupValidate';
import { MultiImageUploader } from '../../common/MultiImageUploader';
import { MyImageInput } from '../../common/SingleImageUploader';
import { SubmitButton } from '../../common/SubmitButton';
import { AccommodationList } from './AccomodationList';
import { FaqList } from './FaqList';
import { ItineraryList } from './ItenaryList';
import { WhatIsIncludedField } from './WhatIsIncludedField';
import { RecommendedTripList } from './RecommendedTripList';
import { RelatedArticlesList } from './RelatedArticlesList';
import { TagList } from './TagList';
import { Configuration } from '../../../api/Config';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import useCopyAdminFormToClipboard from '../../../hooks/useCopyAdminFromToClipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const TripSchema = yup.object({
  cancellationPolicy: yup.string().nullable() as StringSchema<string | null>,
  active: yup.boolean(),
  hidden: yup.boolean(),
  featured: yup.boolean(),
  deleted: yup.boolean(),
  featuredPosition: yup.number(),
  tripType: yup.string().required().oneOf(Object.keys(TripType)) as StringSchema<TripType>,
  hostProfileId: yup.string().nullable() as StringSchema<HostId | null>,
  createdById: yup.string().nullable() as StringSchema<HostId | null>,
  title: yup.string().required(),
  slug: yup
    .string()
    .required()
    .matches(/^[a-z0-9_-]+$/gi, 'Slug can contain only letters, digits, underscore (_) or dash (-)'),
  // restricting description to 160 chars max
  description: yup.string().required().max(160),
  status: yup.string().required().oneOf(Object.keys(TripStatus)).default(TripStatus.Draft) as StringSchema<TripStatus>,
  operator: yup.string().nullable(),
  startingPoint: yup.string().nullable(),
  minPeople: yup.number().nullable(),
  internalNotes: yup.string().nullable(),
  departureType: yup.string().required(),
  country: yup.string().required().oneOf(isoCountryCodeList, 'Country required') as StringSchema<IsoCountryCode>,
  tourType: yup.string().required().oneOf(Object.keys(TourType)) as StringSchema<TourType>,
  difficulty: yup.string().required().oneOf(Object.keys(DifficultyLevel)) as StringSchema<DifficultyLevel>,
  experience: yup.string().required().oneOf(Object.keys(ExperienceLevel)) as StringSchema<ExperienceLevel>,
  accommodationType: yup.string().required().oneOf(Object.keys(AccommodationType)) as StringSchema<AccommodationType>,
  destination: yup.string().nullable() as StringSchema<string | null>,
  days: yup.number().required().min(1),
  maxPeople: yup.number().required().min(1),
  price: yup.number().required().min(1) as NumberSchema<Money>,
  pricePerPerson: yup
    .array<MaybeMoney>()
    .default([null, null, null, null, null, null])
    .of(yup.number().nullable().min(1) as NumberSchema<Money>) as any as Schema<PricePerPerson>,
  currency: yup.string().oneOf(Object.keys(Currency)).default('USD') as StringSchema<Currency>,
  itineraryOnlyPrice: yup.number().nullable().min(1) as NumberSchema<Money | null>,
  itineraryOnlyEnabled: yup.boolean(),
  starRating: yup.number().required().min(1).max(5),
  reviews: yup.number().required(),
  overview: yup.string().required(),
  videoSrc: yup.string().nullable(),
  seoH1: yup.string().nullable(),
  seoTitle: yup.string().nullable(),
  seoDescription: yup.string().nullable(),
  seoCanonicalSlug: yup
    .string()
    .nullable()
    .matches(/^[a-z0-9-]+$/g, 'Canonical slug can contain only letters, digits, or dash (-)'),
  whatIsIncluded: yup.object<WhatIsIncluded>().required().noUnknown().default(WhatIsIncludedDefault).shape({
    allMealsIncluded: yup.boolean().required(),
    alcoholIncluded: yup.boolean().required(),
    gratuitiesIncluded: yup.boolean().required(),
    transfersIncluded: yup.boolean().required(),
    flightsIncluded: yup.boolean().required(),
    accommodationIncluded: yup.boolean().required(),
    admissionFeesIncluded: yup.boolean().required(),
    porterIncluded: yup.boolean().required(),
    visasIncluded: yup.boolean().required(),
    travelInsuranceIncluded: yup.boolean().required(),
    personalGearIncluded: yup.boolean().required(),
    guideFeesIncluded: yup.boolean().required(),
    luggageTransfersIncluded: yup.boolean().required(),
    airportTransfer: yup.boolean().required(),
    internationalFlights: yup.boolean().required(),
    domesticFlights: yup.boolean().required(),
    localGuide: yup.boolean().required(),
    someMeals: yup.boolean().required(),
  }),
  whatIsExcluded: yup.object<WhatIsIncluded>().required().noUnknown().default(WhatIsExcludedDefault).shape({
    allMealsIncluded: yup.boolean().required(),
    alcoholIncluded: yup.boolean().required(),
    gratuitiesIncluded: yup.boolean().required(),
    transfersIncluded: yup.boolean().required(),
    flightsIncluded: yup.boolean().required(),
    accommodationIncluded: yup.boolean().required(),
    admissionFeesIncluded: yup.boolean().required(),
    porterIncluded: yup.boolean().required(),
    visasIncluded: yup.boolean().required(),
    travelInsuranceIncluded: yup.boolean().required(),
    personalGearIncluded: yup.boolean().required(),
    guideFeesIncluded: yup.boolean().required(),
    luggageTransfersIncluded: yup.boolean().required(),
    airportTransfer: yup.boolean().required(),
    internationalFlights: yup.boolean().required(),
    domesticFlights: yup.boolean().required(),
    localGuide: yup.boolean().required(),
    someMeals: yup.boolean().required(),
  }),
  itinerary: yup
    .array<Itinerary>()
    .default([])
    .of(
      yup.object().shape({
        day: yup.number().min(1).required(),
        headline: yup.string().required(),
        description: yup.string().required(),
      }),
    ),
  accommodations: yup
    .array<Accommodation>()
    .default([])
    .of(
      yup.object().shape({
        dayStart: yup.number().min(1).required(),
        numberOfDays: yup.number().min(1).required(),
        accommodationType: yup.string().notRequired().oneOf(Object.keys(AccommodationType)) as StringSchema<AccommodationType | undefined>,
        description: yup.string().required(),
        photos: ImageArray,
        petAllowance: yup.string().notRequired().oneOf(Object.keys(PetAllowance)) as StringSchema<PetAllowance | undefined>,
      }),
    ),
  faq: yup
    .array<Faq>()
    .default([])
    .of(
      yup.object().shape({
        question: yup.string().required(),
        answer: yup.string().required(),
      }),
    ),
  coverImage: OptionalImage,
  gallery: ImageArray,
  recommendedTrips: yup
    .array<RecommendedTrip>()
    .default([])
    .of(
      yup.object().shape({
        tripId: yup.string().required() as StringSchema<TripId>,
      }),
    ),
  relatedArticleTags: yup
    .array<RelatedArticleTags>()
    .default([])
    .of(
      yup.object().shape({
        id: yup.string().required(),
      }),
    ),
  tags: yup.array<string>().default([]).of(yup.string().required()),
  uploaderEmail: yup.string().email().nullable(),
  accommodation: yup.string().nullable(),
  activities: yup.string().nullable(),
  customTripMessage: yup.object<CustomTripMessage>().default(null).nullable().shape({
    default: yup.string().nullable(),
    text: yup.string().nullable(),
  }),
});

export type TripValues = ReturnType<typeof TripSchema.validateSync>;

// To be passed to React Final Form, keeping this func here so that it will be helpful
// for exact form error debugging in future
// const validateFormValues = (schema) => async (values) => {
// 	if (typeof schema === 'function') {
// 		schema = schema();
// 	}
// 	try {
// 		await schema.validate(values, { abortEarly: false });
// 	} catch (err) {
// 		const errors = err.inner.reduce((formError, innerError) => {
//       console.log(formError, innerError.path, innerError.message)
// 			// return setIn(formError, innerError.path, innerError.message);
// 		}, {});

// 		return errors;
// 	}
// };

const validator = yupValidate(TripSchema);

const InitialValues: TripValues = TripSchema.cast();

const uploadTripImg = async (file: File): Promise<UploadedImage> => new ImagesApi().uploadImage('trip', file);

const remove = <T, P extends keyof T>(obj: T, prop: P) => {
  const { [prop]: _, ...rest } = obj;
  return rest;
};

// filter out unwnated entries from an enum
const filterOut = <T, K extends keyof T>(obj: T, exclude: K[]): Omit<T, K> => exclude.reduce(remove, obj as any);

const filteredTourTypes = filterOut(TourType, ['Family', 'Climbing', 'Photography', 'Cycling', 'Cruise']);

const getTripURL = (slug: string): string => {
  if (!slug) return '';
  return `${Configuration.ConsumersUrl}/details/${slug}`;
};

export const TripForm: React.FC<{
  slug?: string;
  trip?: TripValues;
  hosts: { [key: string]: string };
  tripOptions: { [key: string]: string };
  articleCategories: { [key: string]: string };
  onSubmit(trip: TripValues): Promise<void>;
}> = ({ slug, trip, hosts, tripOptions, articleCategories, onSubmit }) => {
  const initialValues = trip || InitialValues;

  const authContext = useAuthContext();
  if (!authContext?.isLoggedIn) return null;

  const history = useHistory();

  const formRef = useRef(null);
  const copyToClipboard = useCopyAdminFormToClipboard(formRef);
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyTripLinkClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!slug) return;
    try {
      await navigator.clipboard.writeText(getTripURL(slug));
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Unable to copy the link', { variant: 'error' });
    }
  };

  const getTripStatusOptions = (currentStatus: TripStatus) => {
    // for Add Trip, show only Draft option
    if (!trip) return filterOut(TripStatus, [TripStatus.Active, TripStatus.Inactive, TripStatus.Archive]);
    // for Edit trip
    if (currentStatus === TripStatus.Draft) {
      if (authContext.account.accountType === 'superadmin') return TripStatus;
      return filterOut(TripStatus, [TripStatus.Active, TripStatus.Inactive]);
    }
    return filterOut(TripStatus, [TripStatus.Draft]);
  };

  // const handleDeleteTrip = async () => {
  //   if (!trip) return null;
  // };

  // const [showDialog, renderDialog] = useConfirmationDialog({
  //   title: 'Delete Trip?',
  //   content: <p>Are you sure you want to delete this trip? You cannot access this trip again once deleted.</p>,
  //   callback: handleDeleteTrip,
  // });

  return (
    <div>
      {slug && (
        <Box maxWidth={640} style={{ marginBottom: '2rem' }}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button size={'large'} variant="contained" color="primary" href={`${getTripURL(slug)}`} target="_blank">
                show preview
              </Button>
            </Grid>
            <Grid item>
              <Button size={'large'} variant="outlined" color="primary" onClick={handleCopyTripLinkClick}>
                copy trip URL
              </Button>
            </Grid>
            <Grid item>
              <div onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
                <FileCopyIcon color="primary" />
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
      <Form<TripValues>
        initialValues={initialValues}
        validate={validator}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        // subscription={{ submitting: true, pristine: true }}
        subscription={{}}
        onSubmit={values => onSubmit(TripSchema.cast(values))}
        render={props => {
          // current form state
          const formState = props.form.getState().values;
          return (
            <form onSubmit={props.handleSubmit} style={{ display: 'flex' }} ref={formRef}>
              <Box maxWidth={'42vw'} marginRight={20}>
                <Grid container spacing={3}>
                  {authContext.isLoggedIn && hasAdminAccess(authContext.account.accountType) && (
                    <>
                      <Field component={MySelect} name="status" label="Trip Status" xs={3} options={getTripStatusOptions(formState.status)} />
                      {/* <Field component={BooleanField} name="active" label="Active" xs={2} /> */}
                      <Field component={BooleanField} name="featured" label="Featured" xs={2} />
                      <Field component={MyTextField} name="featuredPosition" label="Featured Position" xs={3} type="number" />
                      {/* <Field component={BooleanField} name="deleted" label="Deleted" xs={2} /> */}
                      <Field component={BooleanField} name="hidden" label="Hidden" xs={2} />
                    </>
                  )}
                  <Field component={MyTextField} name="title" label="Title" xs={12} required={true} />
                  <Field
                    component={MyTextField}
                    name="slug"
                    label="Slug"
                    xs={9}
                    required={true}
                    disabled={trip && authContext.account?.accountType !== 'superadmin'}
                  />
                  <Field component={MySelect} name="tripType" label="Tour Type*" xs={3} options={TripType} />

                  <Field component={MySelect} name="country" label="Country*" xs={6} options={isoCountryMap} />
                  <Field
                    component={MyTextField}
                    name="destination"
                    label="Destination"
                    xs={6}
                    nullable
                    allowNull
                    parse={value => (value === '' ? null : value)}
                  />

                  <Field component={MyTextField} name="startingPoint" label="Starting Point" xs={12} nullable allowNull />

                  <Field component={MySelect} name="tourType" label="Adventure Type*" xs={3} options={filteredTourTypes} required={true} />
                  <Field component={MySelect} name="difficulty" label="Difficulty*" xs={3} options={DifficultyLevel} />
                  <Field component={MySelect} name="experience" label="Experience*" xs={3} options={ExperienceLevel} />
                  <Field component={MySelect} name="accommodationType" label="Accommodation*" xs={3} options={AccommodationType} />

                  <Field component={MyTextField} name="days" label="Days" xs={3} type="number" required={true} />
                  <Field component={MyTextField} name="maxPeople" label="Max People" xs={3} type="number" required={true} />
                  <Field component={MyTextField} name="minPeople" label="Min People" xs={3} type="number" nullable />
                  <Field component={MySelect} name="departureType" label="Departure Type*" xs={3} options={DepartureType} />

                  <Field component={MyTextField} name="price" label="Price from" xs={6} type="number" required={true} />
                  <Field component={MySelect} name="currency" label="Currency" xs={6} options={Currency} />

                  <Field component={MyPricePerPersonField} name="pricePerPerson" label="Price per person" xs={12} />

                  <Field component={MyTextField} name="starRating" label="Star Rating" xs={3} type="number" />
                  <Field component={MyTextField} name="reviews" label="Reviews" xs={3} type="number" />
                  <Field component={BooleanField} name="itineraryOnlyEnabled" label="Itinerary Only" xs={3} />
                  <Field component={MyTextField} name="itineraryOnlyPrice" label="Itinerary Only Price" xs={3} type="number" />

                  <Field component={MySelect} name="hostProfileId" label="Guide" xs={6} options={hosts} nullable />
                  <Field component={MySelect} name="createdById" label="Created By" xs={6} options={hosts} nullable />

                  <Field component={MyTextField} name="accommodation" label="Accommodation" xs={6} nullable />
                  <Field component={MyTextField} name="activities" label="Activities" xs={6} nullable />

                  <Field component={MyTextField} name="description" label="Description" xs={12} multiline required={true} />

                  <Field component={MyTextField} name="operator" label="Operator" xs={6} nullable />
                  <Field component={MyTextField} name="uploaderEmail" label="Uploaded By" xs={6} nullable disabled />

                  <Grid item xs={12}>
                    <Field component={MyTextField} name="internalNotes" label="Internal Notes" xs={12} multiline />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Overview*</Typography>
                    <Field component={MyTextField} name="overview" label="" xs={12} placeholder={'Overview'} multiline required={true} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Video source</Typography>
                    <Field component={MyTextField} name="videoSrc" label="" xs={12} placeholder={'Mux video playback id'} />
                  </Grid>

                  {authContext?.account?.accountType === 'superadmin' ? (
                    <Grid item xs={12} spacing={3}>
                      <Typography variant={'h4'}>For SEO</Typography>
                      <Field component={MyTextField} name="seoH1" label="H1" xs={12} placeholder={'SEO H1'} style={{ marginBottom: '16px' }} />
                      <Field component={MyTextField} name="seoTitle" label="Title" xs={12} placeholder={'SEO Title'} style={{ marginBottom: '16px' }} />
                      <Field
                        component={MyTextField}
                        name="seoDescription"
                        label="Description"
                        xs={12}
                        placeholder={'SEO Meta Description'}
                        style={{ marginBottom: '16px' }}
                      />
                      <Field component={MyTextField} name="seoCanonicalSlug" label="Canonical Slug" xs={12} placeholder={'SEO Canonical Slug'} />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Itinerary</Typography>
                    <ItineraryList name="itinerary" />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Gallery</Typography>
                    <p>The first picture will be the trip's cover picture</p>
                    <MultiImageUploader name={'gallery'} uploader={uploadTripImg} size={[480, 280]} downloadable />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Accommodations</Typography>
                    <AccommodationList name="accommodations" />
                  </Grid>

                  <Field component={WhatIsIncludedField} label="Included" name="whatIsIncluded" xs={3} />
                  <Field component={WhatIsIncludedField} label="Excluded" name="whatIsExcluded" xs={3} />

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Cancellation Policy</Typography>
                    <Field
                      component={MyTextField}
                      name="cancellationPolicy"
                      label="Cancellation Policy"
                      xs={12}
                      multiline
                      nullable
                      allowNull
                      parse={value => (value === '' ? null : value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>FAQ</Typography>
                    <FaqList name="faq" />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Recommended Trips</Typography>
                    <RecommendedTripList name="recommendedTrips" tripOptions={tripOptions} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'h4'}>Tags</Typography>
                    <TagList name="tags" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h4'}>Related Article Tags</Typography>
                  <RelatedArticlesList name="relatedArticleTags" articleTags={articleCategories} />
                </Grid>
              </Box>
              <div>
                <Box style={{ position: 'sticky', top: '60px', display: 'flex' }}>
                  <SubmitButton caption="Save trip" handleSubmit={props.handleSubmit} />
                  {/* {trip && formState?.status == TripStatus.Draft && (
                    <Button variant="outlined" style={{ color: 'red' }} onClick={() => showDialog()}>
                      Delete
                    </Button>
                  )} */}
                </Box>
              </div>
            </form>
          );
        }}
      />
      {/* {renderDialog()} */}
    </div>
  );
};
