import React, { useState } from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { AccommodationUpgrades } from './AccommodationUpgrades';
import { ExtraActivities } from './ExtraActivities';
import { AddonsApi } from '../../api/AddonsApi';
import { LoadingGuard } from '../common/LoadingGuard';
import { AddonType, Money, TripAddon, TripAddonId, TripId } from '@tripr/common';
import arrayMutators from 'final-form-arrays';
import { ExtraTripList } from './ExtraTrips';
import { TripWithVersionApi } from '../../api/RpcClient';

const useStyles = makeStyles(theme => ({
  root: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  saveButton: {
    marginTop: theme.spacing(4),
  },
}));

interface TripAddonsState {
  accommodations?: Partial<TripAddon>[];
  activities?: Partial<TripAddon>[];
  extraTrips?: Partial<TripAddon>[];
}

export const AddonsForm = ({ tripId }: { tripId: TripId }) => {
  const classes = useStyles();
  const [tripAddons, setTripAddons] = useState<TripAddonsState>({});
  const [upgrades, setUpgrades] = useState<Partial<TripAddon>[]>([]);

  const onSubmit = async (values: any) => {
    try {
      let data: Partial<TripAddon>[] = upgrades.map(
        el =>
          ({
            ...el,
            type: AddonType.Accommodation,
            tripId,
          } as Partial<TripAddon>),
      );

      if (values?.activities?.length) {
        console.log(values?.activities);
        data = data.concat(
          values.activities.map(
            (el: Partial<TripAddon>) =>
              ({
                ...el,
                type: AddonType.Activity,
                tripId,
              } as Partial<TripAddon>),
          ),
        );
      }

      if (values?.extraTrips?.length) {
        data = data.concat(
          values.extraTrips.map(
            (el: Partial<TripAddon> & { addonId: TripAddonId }) =>
              ({
                id: el.addonId ? el.addonId : undefined,
                type: AddonType.ExtraTrip,
                linkedTripId: el.tripId,
                title: '',
                description: '',
                price: 0 as Money,
                currency: 'USD',
                isVisible: true,
                tripId,
              } as Partial<TripAddon>),
          ),
        );
      }

      // return console.log(data);

      const tripAddons = await AddonsApi.saveTripAddons(tripId, data);

      if (tripAddons?.length) {
        await loadData();
      }
      alert('Saved successfully!');
    } catch (error) {
      console.error('Error saving addons:', error);
      alert('Error saving. Please try again.');
    }
  };

  const getGroupedAddons = (addons: Partial<TripAddon>[]) => {
    if (addons?.length) {
      return {
        accommodations: addons.filter(el => el.type == AddonType.Accommodation) || [],
        activities: addons.filter(el => el.type == AddonType.Activity) || [],
        extraTrips: addons.filter(el => el.type == AddonType.ExtraTrip).map(el => ({ addonId: el.id, tripId: el.linkedTripId })) || [],
      };
    }
    return { accommodations: [], activities: [], extraTrips: [] };
  };

  const loadData = async () => {
    const tripAddons = await AddonsApi.getTripAddons(tripId);
    const tripOptions = await TripWithVersionApi.getRecommendedTripOptions();
    // if no addons are added to a particular trip, show default addons
    if (!tripAddons?.length) {
      const addons = await AddonsApi.getAddons();
      const t =
        addons?.map(el => ({
          title: el.title,
          price: Number(el.price),
          type: el.type,
          description: el.description,
          isVisible: false,
          maxQuantity: el.title.includes('Night') ? 1 : undefined,
        })) || [];
      setTripAddons(getGroupedAddons(t as Partial<TripAddon>[]));
      return { tripOptions };
    }
    setTripAddons(getGroupedAddons(tripAddons));
    return { tripOptions };
  };

  return (
    <LoadingGuard loadData={loadData}>
      {({ tripOptions }) => (
        <Form
          initialValues={tripAddons}
          onSubmit={onSubmit}
          mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators,
          }}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  Accommodation Upgrades
                </Typography>
                <AccommodationUpgrades initialUpgrades={tripAddons ? tripAddons?.accommodations : []} onChange={values => setUpgrades(values)} />
              </Box>

              <Box className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  Extra Activities
                </Typography>
                <ExtraActivities name="activities" />
              </Box>

              <Box className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  Extra Trips
                </Typography>
                <ExtraTripList name="extraTrips" tripOptions={tripOptions} />
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                // disabled={submitting}
              >
                Save All Changes
              </Button>
            </form>
          )}
        />
      )}
    </LoadingGuard>
  );
};
